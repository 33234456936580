import { Box, Divider, InputLabel, MenuItem, Modal, Select, Stack, TextField, Typography } from "@mui/material";
import { makeStyles } from "@mui/styles";
import CustomButton from "components/custom-button/custom-button";
import ImageUploadDisplay from "components/file-uploader/image-loaderDisplay";
import CustomSwitch from "components/switch-button/switch-button";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import ErrorExclamation from "../../../assets/exclamation-mark.png";
import { BLACK, BLACK_2, BLUE, DARKGREY, DARK_GRAY, GREY, GREY_3, GREY_4, RED, WHITE } from "utils/colors";
import { HEADER2, SUBHEADER1, SUBHEADERBOLD, SUBHEADERBOLD2 } from "utils/fonts";
import PasswordField from "components/password-field/password-field";
import CheckBox from "components/check-box/check-box";
import { validateEmail } from "utils/helper";
import PhoneInput from "react-phone-input-2";
import Notification from "views/Notifications/Notification";
import HomeApi from "services/HomeAPi";
import OtpInput from "react-otp-input";
import { grey } from "@mui/material/colors";

const EditUserDialogStyle = makeStyles(() => ({
    boxView: {
        display: "flex",
        flexDirection: "column",
        justifyContent: "flex-start",
        alignItems: "flex-start",
        borderRadius: "7.2px",
        marginTop: "10px",
        marginLeft: "20px",
        marginBottom: "10px",
        position: "absolute" as "absolute",
        top: "50%",
        left: "50%",
        transform: "translate(-50%, -50%)",
        width: "100%",
        height: "90%",
        overflowY: 'auto',
        OverflowX: "none",
        maxWidth: 700,
        minWidth: 450,
        background: "white",
    },
    dividerStyleVerticle: {
        borderColor: GREY,
        borderWidth: 0.5,
        opacity: 1,
    },
    otpViewTitle: {
        fontSize: 16,
        fontWeight: "500",
        color: "#1F2328",
        lineHeight: "24px",
    },
    titleText: {
        display: "flex",
        marginTop: 20,
        marginBottom: 20,
        justifyContent: "center",
        alignItems: "center",
    },
    subTitle: {
        fontStyle: "normal",
        fontWeight: 400,
        fontSize: 16,
        lineHeight: "28px",
        textAlign: "left",
        color: BLACK,
    }

}))
function AdvancedSettings(props: any) {
    const { boxView, dividerStyleVerticle, otpViewTitle, titleText, subTitle } = EditUserDialogStyle();
    const { t } = useTranslation()
    const [isValidEmail, setIsValidEmail] = React.useState({ value: true, error: "" })
    const [OTP, setOTP] = React.useState("");
    const [isOTPEmail, setOTPEmail] = React.useState(false);
    const [invalidOtp, setInvalidOtp] = React.useState(false);
    const [isOtpVerified, setOtpVerified] = React.useState(false);
    const [isShowModal, setIsShowModal] = React.useState(false);
    const [roleList, setRoleList] = React.useState([])
    const [roleNameList, setRoleNameList] = React.useState([])
    const [inputRoleData, setInputRoleData] = useState({
        changeRole: "",
        requestAccess: "",
        enterYourPassword: ""
    });

    const [isPasswordNotMatch, setIsPasswordNotMatch] = React.useState(false)
    const [isDisableForAccountManage, setIsDisableForAccountManage] = React.useState(true)
    const [isDisableForRoleManage, setIsDisableForRoleManage] = React.useState(true)
    const [accountManageData, setAccountManageData] = React.useState({
        displayMobileNo: "",
        mobile: "",
        code: "",
        email: "",
        oldPassword: "",
        newPassword: "",
        retypePassword: ""
    });

    function handleOpen() {
        setIsShowModal(true);
    }

    function handleClose() {
        setIsShowModal(false);
    }

    const getUserId = () => {
        let userId;
        const loginUserDetails = localStorage.getItem("user_Details")
        if (loginUserDetails) {
            const details = JSON.parse(loginUserDetails)
            userId = details.id
        }
        return userId
    }

    useEffect(() => {
        if (inputRoleData.changeRole !== "" && inputRoleData.requestAccess !== "" && inputRoleData.enterYourPassword !== "" ) {
            setIsDisableForRoleManage(false)
        } else {
            setIsDisableForRoleManage(true)
        }
    }, [inputRoleData.changeRole, inputRoleData.requestAccess, inputRoleData.enterYourPassword])

    const clearAccountManageData = () => {
        setAccountManageData({
            displayMobileNo: "",
            mobile: "",
            code: "",
            email: "",
            oldPassword: "",
            newPassword: "",
            retypePassword: ""
        })
    }

    const getRoleList = async () => {
        const response = await HomeApi.getRole(getUserId());
        if (response.status === true) {
            setRoleList(response.data)
            let tempArray: any = [];
            response.data.map((role: any) => {
                tempArray.push(role.doc)
            });
            setRoleNameList(tempArray)
        }
    }

    useEffect(() => {
        getRoleList()
    }, [])

    const sendOtpPassword = async () => {
        const payload = {
            email: accountManageData.email
        };

        const response = await HomeApi.sendOtpPassword(payload);

        if (response.status === true) {
            Notification.notifySuccess(response.message)
            handleOpen()
        } else {
            Notification.notifyError(response.message)
        }

    };

    const isCreatePasswordValid = () => {
        if (
            accountManageData.newPassword !== "" &&
            accountManageData.retypePassword !== "" &&
            accountManageData.newPassword !== accountManageData.retypePassword
        ) {
            setIsPasswordNotMatch(true);
        } else {
            setIsPasswordNotMatch(false);
        }
    };

    const verifyOtp = async (value: string, header: any) => {
        const payload = {
            email: accountManageData.email,
            otp: value,
            password: accountManageData.newPassword
        };

        const response = await HomeApi.verifyOTPPassword(payload);
        if (response.status === 200) {
            Notification.notifySuccess(response.message)
            setInvalidOtp(false);
            setOtpVerified(true);
            setOTP("")
            handleClose()
        } else {
            Notification.notifyError(response.message)
            setInvalidOtp(true);
            setOtpVerified(false);
        }
    }

    const renderVerificationOTPView = () => {
        const topMessage = t("YC_SIGN_UP_TEXT_ONE").replace(
            "[email - phone]",
            `${isOTPEmail
                ? `[${accountManageData.email}]`
                : isOTPEmail
                    ? `[${accountManageData.email}]`
                    : `[${accountManageData.mobile}]`
            }`
        );
        return (
            <Box sx={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
                borderRadius: "7.2px",
                marginTop: "10px",
                marginLeft: "20px",
                marginBottom: "10px",
                position: "absolute" as "absolute",
                top: "50%",
                left: "50%",
                transform: "translate(-50%, -50%)",
                width: "100%",
                height: { xs: "20%", sm: "40%", lg: "40%", xl: "40%" },
                overflowY: 'auto',
                OverflowX: "none",
                maxWidth: 600,
                minWidth: 450,
                background: "white",
            }}>
                <Box className={titleText}>
                    <Typography variant="h6" fontSize={24}>
                        {t("YC_VERIFICATION")}
                    </Typography>
                </Box>
                <Box className={subTitle}>
                    <Typography
                        variant="subtitle1"
                        sx={{ ml: 5, mr: 5, textAlign: "left" }}
                        fontSize={16}
                    >
                        {topMessage}
                    </Typography>
                </Box>
                <Divider
                    orientation="vertical"
                    variant="middle"
                    sx={{ mr: 8, ml: 8, mt: 2, background: "white" }}
                    flexItem
                    className={dividerStyleVerticle}
                />
                <Typography sx={{ mt: 1 }} className={otpViewTitle}>
                    {t("YC_OTP")}
                </Typography>
                <OtpInput
                    value={OTP}
                    isDisabled={isOtpVerified}
                    onChange={(value: string, header: any) => {
                        setOTP(value)
                        if (value.length === 4) {
                            verifyOtp(value, header)
                        }
                    }}
                    numInputs={4}
                    separator={<span> {"   "} </span>}
                    containerStyle={{
                        marginTop: 10,
                        justifyContent: "space-evenly",
                        width: "50%",
                    }}
                    inputStyle={{
                        width: 40,
                        height: 40,
                        background: "rgba(215, 215, 215, 0.01)",
                        border: "1px solid #0F5CD0",
                        boxShadow: "2px 4px 8px rgba(0, 0, 0, 0.07)",
                        borderRadius: 5,
                    }}
                />
                {invalidOtp &&
                    <Typography color={RED}>{t("YC_INVALID_OTP")}</Typography>
                }
                <Divider
                    orientation="vertical"
                    variant="middle"
                    sx={{ mr: 8, ml: 8, background: "rgba(0, 0, 0, 0.35)", mt: 3 }}
                    flexItem
                    className={dividerStyleVerticle}
                />
            </Box>
        )
    }

    const renderUserRoleView = () => {
        return (
            <Box sx={{
                borderRadius: "7.2px", margin: 2, padding: 2,
                // boxShadow: 2,
                border: 2,
                width: { xs: "90%", sm: "90%", lg: "40%", xl: "40%" },
                display: "block",
                // marginLeft: "10px",
                borderColor: 'primary.light'
            }}>
                <Box sx={{
                    display: "flex",
                    flexDirection: "column",
                    paddingBottom: "10px"
                }}>
                    <Typography style={SUBHEADERBOLD} sx={{ textAlign: "start" }}>{t("YC_USER_ROLE_2")}</Typography>

                </Box>
                <hr
                    style={{
                        color: BLACK,
                        backgroundColor: BLACK,
                        width: "100%",
                        alignContent: "center",
                        marginTop: 2,
                        height: 1
                    }}
                />
                <Box sx={{
                    display: "flex",
                    width: "100%",
                    flexDirection: "column",
                    justifyContent: "space-between",
                    paddingBottom: "10px",
                }}>
                    <Typography style={SUBHEADERBOLD2} sx={{ textAlign: "start" }} gutterBottom>{t("YC_CHANGE_ROLE")}</Typography>
                    <Box sx={{
                        display: "flex",
                        width: "100%",
                        flexDirection: "row",
                        // justifyContent: "space-between",
                        paddingBottom: "10px",
                    }}>
                        <Box sx={{width: "100%", height: 40, boxShadow: 4 }}>
                            <Select
                                sx={{ height: 40, width: "100%", textAlign: "start" }}
                                value={inputRoleData.changeRole}
                                displayEmpty
                                inputProps={{ "aria-label": "Without label" }}
                                onChange={(event: any) => {
                                    const selectedRole: any = roleNameList.filter((role: any) => (role === event.target.value))
                                    roleList.map((roleData: any) => {
                                        if (roleData.doc === selectedRole[0]) {
                                            setInputRoleData((prevFields: any) => ({
                                                ...prevFields,
                                                changeRole: roleData.doc
                                            }))
                                        }
                                    })
                                }}
                            >
                                {roleNameList.map((item: any) => {
                                    return (
                                        <MenuItem
                                            value={item}
                                        >
                                            {item}
                                        </MenuItem>
                                    );
                                })}
                            </Select>
                        </Box>
                    </Box>
                </Box>
                <Box sx={{
                    display: "flex",
                    width: "100%",
                    flexDirection: "column",
                    justifyContent: "space-between",
                    paddingBottom: "10px"
                }}>
                    <Typography style={SUBHEADERBOLD2} sx={{ textAlign: "start" }} gutterBottom>{t("YC_REQUEST_ACCESS")}</Typography>
                    <Box sx={{
                        display: "flex",
                        width: "100%",
                        flexDirection: "row",
                        paddingBottom: "10px",
                    }}>
                        <Box sx={{ width: "100%", height: 40, boxShadow: 4 }}>
                            <Select
                                sx={{ height: 40, width: "100%", textAlign: "start" }}
                                value={inputRoleData.requestAccess}
                                displayEmpty
                                inputProps={{ "aria-label": "Without label" }}
                                onChange={(event: any) => {
                                    const selectedRole: any = roleNameList.filter((role: any) => (role === event.target.value))
                                    roleList.map((roleData: any) => {
                                        if (roleData.doc === selectedRole[0]) {
                                            setInputRoleData((prevFields: any) => ({
                                                ...prevFields,
                                                requestAccess: roleData.doc
                                            }))
                                        }
                                    })
                                }}
                            >
                                {roleNameList.map((item: any) => {
                                    return (
                                        <MenuItem
                                            value={item}
                                        >
                                            {item}
                                        </MenuItem>
                                    );
                                })}
                            </Select>
                        </Box>
                    </Box>
                </Box>
                <Box sx={{
                    display: "flex",
                    width: "100%",
                    flexDirection: "column",
                    justifyContent: "space-between",
                    paddingBottom: "10px"
                }}>
                    <Typography style={SUBHEADERBOLD2} sx={{ textAlign: "start" }} gutterBottom>{t("YC_ENTER_YOUR_PASSWORD")}</Typography>
                    <Box sx={{
                        display: "flex",
                        width: "100%",
                        flexDirection: "row",
                        // justifyContent: "space-between",
                        paddingBottom: "10px"
                    }}>
                        <Box sx={{ width: "100%", marginTop: "-10px" }}>
                            <PasswordField
                                sx={{ height: "100%", width: "100%" }}
                                label={t("YC_TYPE_OLD_PW")}
                                value={inputRoleData.enterYourPassword}
                                fieldStyle={{ marginTop: 10, fontStyle: "italic" }}
                                maxWidth={'100%'}
                                onChange={(text: any) => {
                                    const trimStr = text.target.value.trimStart();
                                    setInputRoleData((prevFields) => ({
                                        ...prevFields,
                                        enterYourPassword: trimStr,
                                    }))
                                }}
                            />
                        </Box>
                    </Box>
                </Box>
                <hr
                    style={{
                        color: BLACK,
                        backgroundColor: BLACK,
                        width: "100%",
                        alignContent: "center",
                        marginTop: 2,
                        height: 1
                    }}
                />
                <Box sx={{ marginLeft: "10px", display: "flex", width: "100%", justifyContent: "flex-end" }}>
                    <CustomButton
                        disabled={isDisableForRoleManage}
                        placeHolder={t("YC_UPDATED")}
                        textTransform="none"
                        background={isDisableForRoleManage ? DARK_GRAY : BLUE}
                        height="30px"
                        alignItems="center"
                        // marginRight="10px"
                        // marginLeft="20px"
                        width="auto"
                        borderRadius="4px"
                        onClick={() => { }}
                        buttonStyle={{
                            SUBHEADER1
                        }}>
                    </CustomButton>
                    <CustomButton
                        placeHolder={t("YC_CANCEL")}
                        textTransform="none"
                        background={DARKGREY}
                        height="30px"
                        alignItems="center"
                        marginRight="10px"
                        marginLeft="10px"
                        width="auto"
                        borderRadius="4px"
                        onClick={() => { }}
                        buttonStyle={{
                            SUBHEADER1
                        }}>
                    </CustomButton>
                </Box>
            </Box>
        )
    }

    const renderPasswordManageView = () => {
        return (
            <Box sx={{
                borderRadius: "7.2px", margin: 2, padding: 2,
                // boxShadow: 2,
                border: 2,
                width: { xs: "90%", sm: "90%", lg: "100%", xl: "100%" },
                display: "block",
                borderColor: 'primary.light',
            }}>
                <Box sx={{
                    display: "flex",
                    flexDirection: "column",
                    paddingBottom: "10px"
                }}>
                    <Typography style={SUBHEADERBOLD} sx={{ textAlign: "start" }} gutterBottom>{t("YC_PASSWORD_MANAGEMENT")}</Typography>

                </Box>
                <Box sx={{
                    display: "flex",
                    width: "100%",
                    flexDirection: "column",
                    justifyContent: "space-between",
                    paddingBottom: "10px"
                }}>
                    <Box sx={{
                        display: "flex",
                        width: "100%",
                        flexDirection: { xs: "column", sm: "row", lg: "row", xl: "row" },
                        // justifyContent: "space-between",
                        paddingBottom: "10px"
                    }}>
                        <Typography sx={{ width: { xs: "100%", sm: "20%", lg: "20%", xl: "20%" }, textAlign: "start" }} variant="caption">{t("YC_RESET_PASSWORD")}</Typography>
                        <Box sx={{
                            width: { xs: "100%", sm: "35%", lg: "35%", xl: "35%" },
                            marginTop: { xs: "5px", sm: "-10px", lg: "-10px", xl: "-10px" }
                        }}>
                            <PasswordField
                                sx={{ height: "100%" }}
                                label={t("YC_TYPE_OLD_PW")}
                                value={accountManageData.oldPassword}
                                fieldStyle={{ marginTop: 10, fontStyle: "italic" }}
                                maxWidth={{ xs: "100%", sm: "80%", lg: "80%", xl: "80%" }}
                                onChange={(text: any) => {
                                    const trimStr = text.target.value.trimStart();
                                    setAccountManageData((prevFields) => ({
                                        ...prevFields,
                                        oldPassword: trimStr,
                                    }))
                                }}
                            />
                        </Box>
                        <Box sx={{
                            width: { xs: "100%", sm: "35%", lg: "35%", xl: "35%" },
                            marginTop: { xs: "5px", sm: "-10px", lg: "-10px", xl: "-10px" }
                        }}>
                            <PasswordField
                                sx={{ height: "100%" }}
                                label={t("YC_TYPE_NEW_PASSWORD")}
                                value={accountManageData.newPassword}
                                fieldStyle={{ marginTop: 10, fontStyle: "italic", }}
                                maxWidth={{ xs: "100%", sm: "80%", lg: "80%", xl: "80%" }}
                                onChange={(text: any) => {
                                    const trimStr = text.target.value.trimStart();
                                    setAccountManageData((prevFields) => ({
                                        ...prevFields,
                                        newPassword: trimStr,
                                    }))
                                }}
                            />
                        </Box>
                        <Box sx={{
                            width: { xs: "100%", sm: "35%", lg: "35%", xl: "35%" },
                            marginTop: { xs: "5px", sm: "-10px", lg: "-10px", xl: "-10px" }
                        }}>
                            <PasswordField
                                label={t("YC_RETYPE_PASSWORD")}
                                value={accountManageData.retypePassword}
                                fieldStyle={{ marginTop: 10, fontStyle: "italic" }}
                                maxWidth={{ xs: "100%", sm: "80%", lg: "80%", xl: "80%" }}
                                onChange={(text: any) => {
                                    const trimStr = text.target.value.trimStart();
                                    setAccountManageData((prevFields) => ({
                                        ...prevFields,
                                        retypePassword: trimStr,
                                    }))
                                    isCreatePasswordValid();
                                }}
                            />
                        </Box>
                    </Box>
                    <Box sx={{
                        display: "flex",
                        width: "100%",
                        flexDirection: "row",
                        justifyContent: "flex-end",
                        paddingBottom: "10px"
                    }}>
                        {
                            isPasswordNotMatch && (
                                <InputLabel style={{ color: RED, textAlign: 'start', fontSize: 12, width: '52%', marginRight: "20px" }}>{t("YC_PASSWORD_NOT_MATCH")}</InputLabel>
                            )
                        }
                    </Box>
                </Box>

                <Box sx={{ display: "flex", flexDirection: "row" }}>
                    <Typography sx={{ width: "20%", textAlign: "start" }} variant="caption">{t("YC_SESSION")}</Typography>
                    <Box sx={{ marginLeft: "30px", justifyContent: "flex-start" }}>
                        <Box sx={{ marginLeft: "10px", width: "100%", display: "flex", justifyContent: "flex-start" }}>
                            <CheckBox
                                sx={{ color: grey, marginLeft: "10px", "&.Mui-checked": { color: grey } }}
                                value="top"
                                label={t("YC_LOG_OUT_EVERYWHERE")}
                            // labelPlacement="end"
                            />
                        </Box>
                        <label style={{ color: "red", fontStyle: "italic", marginTop: "10px", marginLeft: "10px", marginBottom: "20px" }}>
                            {t("YC_ACCOUNT_SESSION_LOGOUT_TEXT")}</label>
                    </Box>
                </Box>
                <hr
                    style={{
                        color: BLACK,
                        backgroundColor: BLACK,
                        width: "90%",
                        alignContent: "center",
                        margin: "auto",
                        marginTop: 7,
                        height: 2
                    }}
                />
                <Typography style={SUBHEADERBOLD2} sx={{ mt: "10px", textAlign: "start" }} gutterBottom>{t("YC_TWO_TYPE_AUTHENTICATION")}</Typography>

                <Box sx={{
                    marginLeft: { xs: "10%", sm: "25%", lg: "25%", xl: "25%" },
                    width: { xs: "35%", sm: "20%", lg: "20%", xl: "20%" },
                    display: "flex", flexDirection: "row", justifyContent: "space-between"
                }}>
                    <Typography style={SUBHEADERBOLD2} sx={{ mt: "10px", textAlign: "start" }} gutterBottom>{t("YC_ENABLE")}</Typography>
                    <Typography style={SUBHEADERBOLD2} sx={{ mt: "10px", textAlign: "start" }} gutterBottom>{t("YC_CHANNEL")}</Typography>
                </Box>
                <Box sx={{
                    display: "flex",
                    width: "80%",
                    flexDirection: "row",
                    justifyContent: "space-evenly",
                    paddingBottom: "10px",
                    marginLeft: "15%"
                }}>
                    {/* <Typography variant="caption" visibility={"hidden"}>Sommm</Typography> */}
                    <Box sx={{ width: "10px" }} />
                    <CheckBox
                        sx={{ color: BLACK, "&.Mui-checked": { color: BLACK } }}
                        value="top"
                        labelPlacement="end"
                        checked={isOTPEmail}
                        onChange={(value: any) => {
                            setOTPEmail(value.target.checked);
                        }}
                    />
                    <label style={{ marginTop: 4 }}>{t("YC_EMAIL")}</label>
                    <Box sx={{ width: "50px" }} />
                    <Box >
                        <Box sx={{ width: "100%", boxShadow: 4, height: "40px" }}>
                            <TextField style={{
                                fontStyle: "italic",
                                width: "100%"
                            }} variant="outlined"
                                label="pm@themyp.com"
                                size="small"
                                id="mobile"
                                value={accountManageData.email}
                                onChange={(text) => {
                                    const trimStr = text.target.value.trimStart();
                                    const validEmail = validateEmail(trimStr);
                                    setIsValidEmail({ value: validEmail, error: !validEmail ? t("YC_EMAIL_VALID") : "" })
                                    setAccountManageData((prevFields) => ({
                                        ...prevFields,
                                        email: trimStr,
                                    }));
                                }}
                            />
                        </Box>
                        {(accountManageData.email !== "" && !isValidEmail.value) && (<div style={{ color: "red" }}> {t("YC_EMAIL_VALID")} </div>)}
                        {/* </Box> */}
                    </Box>
                </Box>
                <Modal
                    open={isShowModal}
                    onClose={handleClose}
                    aria-labelledby="modal-modal-title"
                    aria-describedby="modal-modal-description"
                >
                    <div>
                        {renderVerificationOTPView()}
                    </div>
                </Modal>
                <Box sx={{
                    display: "flex",
                    width: "75%",
                    flexDirection: "row",
                    justifyContent: "flex-start",
                    paddingBottom: "10px",
                    marginLeft: "25%"
                }}>
                    <label style={{ color: "red", fontStyle: "italic", marginBottom: "20px" }}>
                        {t("YC_TIME_BASED_OTP")}:</label>
                </Box>
                <Box sx={{
                    display: "flex",
                    width: "80%",
                    flexDirection: "row",
                    justifyContent: "space-evenly",
                    paddingBottom: "10px",
                    marginLeft: "15%"
                }}>
                    {/* <Typography variant="caption" visibility={"hidden"}>Sommm</Typography> */}
                    <Box sx={{ width: "10px" }} />
                    <CheckBox
                        sx={{ color: BLACK, "&.Mui-checked": { color: BLACK } }}
                        value="top"
                        labelPlacement="end"
                    />
                    <label style={{ marginTop: 4 }}>{t("YC_MOBILE")}</label>
                    {/* <TextField label="+235512568" variant="outlined" size="small" /> */}
                    <Box sx={{ width: "50px" }} />
                    <Box>
                        <Box sx={{ width: "100%", boxShadow: 4, height: "40px" }}>
                            <PhoneInput
                                country={"ma"}
                                value={accountManageData.displayMobileNo}
                                containerStyle={{ marginLeft: 5, marginTop: 3, border: 'none', height: 40, background: '#F9F9FA' }}
                                buttonStyle={{ background: '#F9F9FA', border: 'none' }}
                                inputStyle={{ border: 'none', width: "100%", height: 40, background: '#F9F9FA', fontSize: 12, color: BLACK }}
                                onChange={(phone: string, country: any) => {
                                    const reducedPhone = phone.replace(
                                        country.dialCode, '',);
                                    setAccountManageData((prevFields) => ({
                                        ...prevFields,
                                        displayMobileNo: phone,
                                        mobile: reducedPhone,
                                        code: country.dialCode
                                    }))
                                }}
                            />
                        </Box>
                    </Box>
                </Box>
                <Box sx={{
                    display: "flex",
                    width: "75%",
                    flexDirection: "row",
                    justifyContent: "flex-start",
                    paddingBottom: "10px",
                    marginLeft: "25%"
                }}>
                    <label style={{ color: "red", fontStyle: "italic", marginBottom: "20px" }}>
                        {t("YC_TIME_BASED_OTP")}:</label>
                </Box>

                <Box sx={{ float: "right" }}>
                    <CustomButton
                        disabled={isDisableForAccountManage}
                        placeHolder={t("YC_SET_NOW")}
                        textTransform="none"
                        background={isDisableForAccountManage ? DARK_GRAY : BLUE}
                        height="30px"
                        alignItems="center"
                        marginRight="10px"
                        marginLeft="20px"
                        width="auto"
                        borderRadius="4px"
                        onClick={() => sendOtpPassword()}
                        buttonStyle={{
                            SUBHEADER1
                        }}>
                    </CustomButton>
                    <CustomButton
                        placeHolder={t("YC_CANCEL")}
                        textTransform="none"
                        background={DARKGREY}
                        height="30px"
                        alignItems="center"
                        marginRight="10px"
                        marginLeft="20px"
                        width="auto"
                        borderRadius="4px"
                        onClick={() => clearAccountManageData()}
                        buttonStyle={{
                            SUBHEADER1
                        }}>
                    </CustomButton>
                </Box>
            </Box>
        )
    }

    return (
        <Box>
            <Box
                sx={{
                    display: "flex",
                    flexDirection: { xs: "column", sm: "column", lg: "row", xl: "row" },
                    height: "100%",
                    width: { xs: "100%", sm: "100%", lg: "95%", xl: "95%" },
                    justifyContent: "space-between",
                    marginLeft: { xs: "0%", sm: "0%", lg: "10px", xl: "10px" },
                }}
            >
                {renderUserRoleView()}
                {renderPasswordManageView()}
                <Box sx={{ height: "200px" }} />
            </Box>
        </Box>
    );
}

export default AdvancedSettings
